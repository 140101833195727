import i18next from 'i18next'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { ReactComponent as Close } from '../icons/close.svg'

const TermsContainersTable = () => {
  return <img src="https://bopaq-files.s3.amazonaws.com/resources/bo-containers+table.jpg" alt="containers-table" />
}

const TermsHtmlEng = ({ className, showHeading = true }: { className?: string; showHeading?: boolean }) => {
  return (
    <div className={`${className} terms-text`}>
      {showHeading && (
        <>
          <h1>Bopaq</h1>
          <h2>Terms and Conditions of Use</h2>
        </>
      )}
      <h3>General</h3>
      <p>
        The following terms and conditions of use (“Terms”) govern the access to and the use of the Bo online app app.bopaq.com (the “App”) and bopaq.com (the “Website”). The access to and use of the App and the Website are based on the
        assumption that these Terms have been read, understood and accepted by the user. The user are also responsible for ensuring that all users who access our App and Website through their internet connection are aware of the following
        terms of use and other applicable terms and conditions, and that they comply with them.
      </p>
      <h3>Products</h3>
      <p>
        Bo provides reusable food packaging (“Product(s)”) to participating restaurants (“Partners”) so that subscribing customers may borrow the Products using the Bo App (our “Service”). The Service is provided to the user subject to the
        following Terms.
      </p>
      <h3>Amendments</h3>
      <p>
        Bo reserves the right to amend or to update all or part of these Terms at any time. Any amendments or updates to the Terms shall be binding upon publication in this section of the website. The user agrees to carefully review these
        Terms from time to time in order to apprise themselves of all amendments, and agrees that each time the user rents a Product, the most recent and updated version of the Terms posted on the Website shall apply. If the user does not
        agree to all or part of the Terms, the user undertakes not to use the Bo Service, App or Website.
      </p>
      <h3>Membership</h3>
      <h3>Membership</h3>
      <p>
        The user may  become  a  member  by  subscribing  to  the  Bo Service. Bo reserves the right to revise and to update the applicable fees for memberships, and the different membership packages available, at any time. Any such
        revision or updates to the fees shall apply prospectively to any membership entered into following the effective date of the fee revision or update. The user authorizes Bo to store the user’s payment method(s) and to automatically
        charge the user’s payment method(s) the retail cost of containers that are not returned past the 14-day borrow period at the then-current list price by format of each unit kept, plus applicable taxes (QST or GST). The user remains
        liable for balances incurred during the term of their subscription even if membership is canceled. In the case that a user decides to return containers as a guest without creating a Bo account, the user agrees that Bo registers
        their information temporarily in order to process the refund of the deposit.
      </p>
      <h3>Payment</h3>
      <p>
        Membership to Bo’s reusable food packaging service is free as long as borrowed containers are returned to a Bo Return Station following return procedure within 14 days. Upon the 15th day, overdue containers will be charged list
        price  based on the number of units by format (see Appendix I) &amp; payment will be processed through  the  Bo App through the user’s credit card registered on file prior to using the Service. The user  agrees to provide  Bo  with 
        complete  and  accurate payment  &amp; billing information upon subscription. If Bo does not receive payment authorization or it is subsequently canceled,  Bo reserves the right to terminate or suspend the user’s access to the
        Service.
      </p>
      <h3>Fees</h3>
      <p>
        The user  may opt to use a Product at any of our Partner’s locations as listed in the App. The user  is required to register on the App and to enter valid credit card or details into the App in order to borrow a Product. In the case
        of a free borrow, the user agrees  that  an  authorization  hold  will  be  placed  on  the user’s  credit  or  debit card in the amount set out in Appendix I to  cover  all  or  any  part of  the  amounts  due  under  these  Terms.
      </p>
      <h3>Incomplete Products</h3>
      <p>
        Bo containers formats 16 &amp; 32 include the base container and its lid. The user must always borrow a full Product. If the user borrows an incomplete product (for example without the lid), and accordingly   returns   an  
        incomplete   product,  Bo reserves the right  to   charge   the user for the list price of the complete product  in accordance with the fees set out in Appendix I in the case of a free borrow. In the case of a borrow with paid
        deposit, no refund of deposit will be made on products returned incomplete or damaged.
      </p>
      <h3>Title</h3>
      <p>
        The user agrees and acknowledges that the legal title to the Products shall remain with Bo until a unit is SOLD &amp; FULLY PAID FOR upon expiry of the borrow period of 14-days in the case of a free borrow OR once Bo sends an
        official notice (at its sole discretion) that a product is returned incomplete or damaged, at which point ownership transfers to the user.
      </p>
      <h3>Use of Products</h3>
      <p>
        The user agrees that the user shall only use the Products for the transport of food or drink. The user shall not use the Products for any other purpose. The user agrees to keep the Products in good care and condition and not to keep
        the Products in unsanitary conditions. Bo reserves the right to suspend user accounts suspected of abuse.
      </p>
      <h3>Returns</h3>
      <p>
        The user agrees to return the Product within 14 days at one of the Partner locations listed in the App in the case of a free borrow. The user agrees to follow all of the instructions on the App to execute the return of the Product.
        If the user fails to do so, Bo’s system shall not register that the user has returned the Product. In such a case, Bo may deem that the user has failed to return the Product (or any part of it) within the 14 day limit and Bo may
        charge the user a fee in accordance with Appendix I below. In the case of borrow with paid deposit, the user can choose to return the product to one of the Partner return locations listed in the App following the same instructions
        to receive a deposit reimbursement within 14 days, only if the products are returned in a complete and acceptable condition. Only products that were assigned with deposit will be processed for a deposit reimbursement. Any other
        product will be processed without deposit reimbursement.
      </p>
      <h3>Penalties</h3>
      <p>
        In the case of a free borrow, if the user fails to return the Product before the expiry of the borrow period of 14-days OR once Bo sends an official notice (at its sole discretion) that a product is returned incomplete or damaged,
        Bo shall immediately charge the user the fees set out in Appendix I to the user’s credit card. This is not applicable for borrows with paid deposit.
      </p>
      <h3>Damage</h3>
      <p>
        The user shall not borrow a defective, damaged (beyond fair wear or tear) or defaced Product. If the user borrows such a Product, the user must notify Bo of such a condition by emailing us at bopaq.com If the user fails to notify,
        Bo may deem that the user caused the damage during the period of the user’s rental, and the user may be liable to compensate Bo in accordance with the fees set out in Appendix I.
      </p>
      <h3>Loss</h3>
      <p>
        In the case of a free borrow, if the Product or any part of it is stolen, lost or damaged during the 14 day period of the user’s rental, the user shall contact Bo immediately by emailing us at info@bopaq.com and return the Product
        to an employee at a Partner location. The user shall be liable to compensate Bo for the missing or damaged Product in accordance with the fees set out in Appendix I. In the case of borrow with paid deposit, Bo is not responsible for
        processing deposit reimbursements for products that were stolen, lost or damaged.
      </p>
      <h3>Charges</h3>
      <p>
        The user hereby authorizes Bo to charge the credit card associated with the user’s account for any fees incurred by the user under the above provisions. Please contact us within 3 days if the user does not agree with a charge on the
        user’s account.
      </p>
      <h3>Liability</h3>
      <p>Bo is not responsible for any issues in relation to the quality of beverages, food or service provided at our Partner’s locations. The user agrees that the user shall indemnify and hold Bo harmless in respect of such issues.</p>
      <h3>App and Website</h3>
      <ul>
        <li>
          The user shall be liable for the use of the App and Website and its contents. Bo shall not be liable for any use of the App or Website and its contents by any of its users that is not compliant with applicable law. The user shall
          be liable if it communicates information or data which is incorrect, false or concerning third parties without the latter’s consent, as well as for any improper use of such data or information.
        </li>
        <li>
          The user acknowledges that material will be downloaded or obtained via the internet for use with the App and the Website, and the user has chosen to use the same at the user's own risk and the user is responsible for any damage to
          its computer system or loss of data as a consequence of downloading content from the website.
        </li>
        <li>
          Bo accepts no responsibility whatsoever for any damage resulting from inaccessibility to the App or the Website services or from damage  caused by viruses, corrupt files, errors, omissions, service interruptions, content deletion,
          problems with the network, with providers or telephone and/or data transmission connections, unauthorized access, data alteration, failure and/or malfunction of the user's electronic equipment.
        </li>
      </ul>
      <p>
        Notwithstanding anything herein to the contrary, Bo’s liability towards the user in connection with the Products, the Service, the App and the Website shall at all times be limited to 100% of any fees paid by the user under these
        Terms. In no event shall Bo be liable for indirect, consequential, incidental or punitive damages or losses whether arising under contract, in tort (including negligence), at law or in equity, and including but not limited to: loss
        of actual or anticipated profits, loss of revenue, loss of use, loss of goodwill, cost of capital or any consequential loss including losses in respect of business   interruption.
      </p>
      <h3>Indemnification</h3>
      <p>The user agrees to fully indemnify, pay and hold Bo harmless from and against any and all claims, liabilities and/or losses of whatever nature suffered arising out of or in connection with:</p>
      <ul>
        <li>any and all damage caused by the user to a Partner, or to its place of business;</li>
        <li>the user’s negligent acts, or omissions, or wilful misconduct in connection with the Service or these Terms;</li>
        <li>any damage, loss, death or personal injury caused by the user’s use of the Products or the Service; or</li>
        <li>any claim by a third party in connection with the user’s performance of these Terms or use of the Products or Service.</li>
      </ul>
      <h3>Suspension</h3>
      <p>Bo may suspend, modify, withdraw or restrict the availability of all or any part of our Services, App or Website for business and operational reasons.</p>
      <h3>Contact</h3>
      <p>In the event of a problem in using our Services, App or Website, please contact info@bopaq.com</p>
      <h3>Interruptions</h3>
      <p>
        Although we will try to ensure regular access to the App and Website, the dynamic nature of the Internet and its content may not allow us to operate without any suspensions, interruptions or disconnections linked to updating the App
        and Website.
      </p>
      <h3>Account Details</h3>
      <p>
        The user is responsible  for  maintaining  the  confidentiality  and  the proper use of the user’s personal information, including the credentials for accessing restricted services, and is also responsible for any damage or harm
        which might arise against Bo or third parties as a result of the user’s improper use, loss or removal of such   information.
      </p>
      <h3>Personal Data</h3>
      <p>
        The user consents to and acknowledges that Bo may collect, use and disclose the user’s personal data in accordance with Bo’s Privacy Policy which applies to users who access the App and Website and who use the Services without
        making purchases. The Privacy Policy will help   the user understand how and for what purposes Bo collects and uses the user’s personal  information.
      </p>
      <h3>Intellectual Property</h3>
      <p>
        The App and Website’s contents, including but not limited to, works, images,  pictures,  dialogues,  music,  sounds,  videos,  documents, drawings, figures, logos and any other material published on the App and Website, including
        navigations, web pages, graphics, tools, the App and Website's fonts and design, diagrams, methods, processes, functions, HTML code and software which  are part of the App and the Website, and are protected by copyright and by all
        other intellectual property rights of Bo and the relevant rights holders.
      </p>
      <h3>Infringement</h3>
      <p>
        The reproduction, wholly or in part, and in any form whatsoever, of the Products, App and the Website and of their contents is forbidden without our prior written consent. We have the exclusive right to authorize or prohibit any
        reproduction, be it direct or indirect,   temporary or permanent, or in any other manner or form, and in whole or in part, of the App and Website and their contents.
      </p>
      <h3>Website</h3>
      <p>
        As far as the use of the Website is concerned, the user is only authorized to view the website and its contents. The user is also authorized to carry out all the other temporary acts of reproduction (other than for commercial or
        business purposes), which are transient or incidental and an integral and essential part of viewing the Website and its contents.
      </p>
      <h3>Unauthorized Use</h3>
      <p>
        In no event shall the user be authorized to do any act which may invalidate or otherwise be inconsistent with our intellectual property rights, including copying, transferring, selling, reverse engineering, or modifying our App,
        Website or Products.
      </p>
      <h3>Links to Third Party Sites</h3>
      <p>
        The App and Website may contain hyperlinks ("links") to other websites which are in no way connected to Bo. Bo neither controls nor performs monitoring operations of these websites and their content. Bo may not be held liable for
        the content of these sites and the rules adopted by them, including as concerns the user’s privacy and the processing of the user’s personal data during the user’s navigation. Bo asks that the user be vigilant when the user connects
        to these websites through the links present on the App and the Website and that the user carefully reads their conditions of use and privacy regulations. Bo reminds the user that these Terms and the Privacy Policy do not apply to
        websites operated by third parties. Bo provides links to other websites exclusively to help its users search and browse and to facilitate links to other websites on the Internet. The activation of links does not entail any
        recommendation or notification from Bo to access and navigate on these websites, nor any guarantee as to their content, services or the goods provided and sold by them to Internet users.
      </p>
      <h3>Disclaimer</h3>
      <p>
        Although Bo uses reasonable efforts to ensure that the content of the App and Website is accurate and does not contain any incorrect or outdated information, Bo makes no representations, warranties or guarantees, whether expressed
        or implied, that the content on Bo’s site is accurate, complete or up to date. Bo shall not be liable for the accuracy or completeness of the content posted on the App or the Website.
      </p>
      <h3>User Generated Content</h3>
      <p>Bo may include information and materials uploaded by other users   of the App or Website, including to bulletin boards and chat rooms. This information and these materials have not been verified or approved by Bo.</p>
      <h3>Breach of Terms</h3>
      <p>Bo reserves its right to cease the user’s participation in the Service for any reason, including due to the user’s breach of these Terms. Any fees the user has paid shall not be refunded to the user.</p>
      <h3>Cancellation</h3>
      <p>The user agrees that any additional fees arising under these Terms may still be charged to the user.</p>
      <p>Upon termination, the user shall return any Products that the user has borrowed and/or pay any outstanding fees accrued.</p>
      <h3>Governing Law</h3>
      <p>
        The user agrees that any dispute arising from the use of the Products, the Website or the App shall be governed by the laws of Quebec, Canada. The courts of Montreal shall have exclusive jurisdiction to hear any matter related to
        this agreement.
      </p>
      <h3>Language</h3>
      <p>Les parties aux présentes conviennent à ce que l’entente soit rédigée en langue anglaise. The parties consent to have the present agreement drafted in English.</p>
      <h2>Appendix I</h2>
      <p>
        In the case of a free borrow, in the event that a user does not return a Product in their inventory past its return due date of 14 days from when a container was checked into their account from a merchant OR if a Product is returned
        &amp; deemed incomplete or damaged (see sections 7 &amp; 12), the user agrees to purchase the Bo container at the list price below plus applicable taxes (QST+GST). The payment will be automatically charged on the 15th day on the
        method of payment registered upon registration.
      </p>
      <hr />
      <TermsContainersTable />
    </div>
  )
}

const TermsHtmlFr = ({ className, showHeading = true }: { className?: string; showHeading?: boolean }) => {
  return (
    <div className={`${className} terms-text`}>
      {showHeading && (
        <>
          <h1>Bopaq</h1>
          <h2>Termes et conditions d'utilisation</h2>
        </>
      )}
      <h3>Les Termes et Conditions Générales</h3>
      <p>
        Les conditions d'utilisation suivantes (« Conditions ») régissent l'accès et l'utilisation de l'application en ligne Bo (l'« Application ») et de bopaq.com (le « Site Web »). L'accès et l'utilisation de l'Application et du Site Web
        sont basés sur l'hypothèse que les présentes Conditions ont été lues, comprises et acceptées par l'utilisateur. L'utilisateur est également responsable de s'assurer que tous les utilisateurs qui accèdent à notre application et à
        notre site Web via leur connexion Internet sont au courant des conditions d'utilisation suivantes et des autres conditions générales applicables, et qu'ils les respectent.
      </p>
      <h3>Les Produits</h3>
      <p>
        Bo fournit des boîtes de nourriture réutilisables (« Produit(s) ») aux restaurants participants (« Partenaires ») afin que les clients abonnés puissent emprunter les Produits à l'aide de l'Application Bo (notre « Service »). Le
        Service est fourni à l'utilisateur sous réserve des Conditions suivantes.
      </p>
      <h3>Les Modifications</h3>
      <p>
        Bo se réserve le droit de modifier ou de mettre à jour tout ou partie des présentes Conditions à tout moment. Toute modification ou mise à jour des Conditions sera contraignante dès sa publication dans cette section du site Web.
        L'utilisateur accepte de revoir attentivement ces Conditions de temps à autre afin de prendre connaissance de toutes les modifications, et accepte que chaque fois que l'utilisateur loue un Produit, la version la plus récente et mise
        à jour des Conditions affichées sur le Site Web s'applique. Si l'utilisateur n'accepte pas tout ou partie des Conditions, l'utilisateur s'engage à ne pas utiliser le Service, l'Application ou le Site Web Bo.
      </p>
      <h3>L’Adhésion</h3>
      <p>
        L'utilisateur peut devenir membre en s'abonnant au service Bo. Bo se réserve le droit de réviser et de mettre à jour les frais applicables aux abonnements (y compris les frais définis sur les magasins d'applications concernés où
        l'utilisateur s'abonne au Service) et les différents forfaits d'abonnement disponibles, à tout moment. Toute révision ou mise à jour des frais s'appliquera prospectivement à toute adhésion conclue après la date d'entrée en vigueur
        de la révision ou de la mise à jour des frais. L'utilisateur autorise Bo à sauvegarder le(s) mode(s) de paiement de l'utilisateur et à facturer automatiquement le(s) mode(s) de paiement de l'utilisateur chaque mois ou chaque année
        (le cas échéant) au taux alors en vigueur pour le forfait utilisateur, plus les taxes applicables (telles que la TVQ ou TPS si le tarif ne l'inclut pas) jusqu'à ce que l'utilisateur annule. Dans le cas où un utilisateur décide de
        faire un retour de contenants sans créer un compte, il agrée à ce que Bo enregistre temporairement l’information fournie pour procéder le remboursement de la consigne.
      </p>
      <h3>Les Frais</h3>
      <p>
        L'utilisateur peut choisir d'utiliser un produit dans l'un des emplacements de nos partenaires répertoriés dans l'application. L'utilisateur est tenu de télécharger l'application et d'entrer des informations de carte de crédit ou de
        débit valides dans l'application afin d'emprunter un produit. L'utilisateur accepte qu'une retenue d'autorisation soit placée sur la carte de crédit ou de débit de l'utilisateur pour le montant indiqué à l'annexe 1 pour couvrir tout
        ou partie des sommes dues en vertu des présentes Conditions dans le cas d’un prêt gratuit. 
      </p>
      <h3>Les Produits incomplets</h3>
      <p>
        Un produit se compose d'un contenant de base et de son couvercle. L'utilisateur doit toujours emprunter un Produit complet. Si l'utilisateur emprunte un produit incomplet (par exemple sans le couvercle), et retourne en conséquence
        un produit incomplet, Bo se réserve le droit de facturer à l'utilisateur selon les tarifs indiqués en annexe 1 dans le cas d’un prêt gratuit. Dans le cas d’un prêt consigné, aucun remboursement de dépôt sera effectué pour un produit
        retourné incomplet ou endommagé. 
      </p>
      <h3>Le Titre</h3>
      <p>
        L'utilisateur accepte et reconnaît que le titre légal des Produits restera avec Bo et ne passera à l'utilisateur qu’après le paiement au prix par item indiqués en annexe 1 après l’expiration du terme de prêt de 14 jours dans le cas
        d’un cas d’un prêt gratuit.
      </p>
      <h3>L’Utilisation des Produits</h3>
      <p>
        L'utilisateur s'engage à utiliser les Produits que pour le transport de nourriture ou de boisson. L'utilisateur ne doit pas utiliser les Produits à d'autres fins. L'utilisateur s'engage à conserver les Produits en bon état et à ne
        pas conserver les Produits dans des conditions insalubres.
      </p>
      <h3>Les Retours</h3>
      <p>
        L'utilisateur s'engage à retourner le produit dans un délai de 14 jours à l'un des emplacements partenaires répertoriés dans l'application dans le cas d’un prêt gratuit. L'utilisateur s'engage à suivre toutes les instructions sur
        l'application pour exécuter le retour du produit. Si l'utilisateur ne suit pas toutes les instructions, le système de Bo ne pourra pas enregistrer que l'utilisateur a retourné le produit. Dans un tel cas, Bo peut considérer que
        l'utilisateur n'a pas retourné le produit (ou une partie de celui-ci) dans un délai de 14 jours et Bo peut facturer à l'utilisateur des frais conformément à la section 10 ci-dessous. Dans le cas d’un prêt consigné, l’utilisateur
        peut choisir de retourner le produit à l'un des emplacements partenaires répertoriés dans l'application suivant les mêmes instructions pour recevoir un remboursement de consigne dans un délai de 14 jours conformément à ce que les
        produits ont été retournés complets et en condition acceptable. Seuls les produits qui ont été attribués avec une consigne seront traités pour un remboursement de consigne. Tout autre produit va être traité sans remboursement tel
        que décrit dans un prêt gratuit.
      </p>
      <h3>Les Pénalités</h3>
      <p>
        Dans le cas d’un prêt gratuit, si l'utilisateur ne retourne pas le produit dans les 14 jours pour une raison quelconque, Bo facturera immédiatement à l'utilisateur les frais indiqués à l'annexe 1 sur la carte de crédit de
        l'utilisateur. Ceci n’est pas applicable dans le cas d’un prêt consigné.
      </p>
      <h3>Les Dommages</h3>
      <p>
        L'utilisateur ne doit pas emprunter un Produit défectueux, endommagé (au-delà de l'usure normale) ou dégradé. Si l'utilisateur emprunte un tel produit, l'utilisateur doit informer Bo d'une telle condition en nous envoyant un e-mail
        à bopaq.com. L'utilisateur peut être tenu d'indemniser Bo conformément aux frais indiqués à l'annexe 1.
      </p>
      <h3>La Perte</h3>
      <p>
        Dans le cas d’un prêt gratuit, si le produit ou une partie de celui-ci est volé, perdu ou endommagé pendant la période de 14 jours de location de l'utilisateur, l'utilisateur doit contacter Bo immédiatement en nous envoyant un
        e-mail à info@bopaq.com et retourner le produit employé à un Emplacement du partenaire. L'utilisateur sera tenu d'indemniser Bo pour le Produit manquant ou endommagé conformément aux frais indiqués à l'Annexe 1. Dans le cas d’un
        prêt consigné, Bo n’est pas responsable de traiter le remboursement pour les produits soit volés, perdus ou endommagés. 
      </p>
      <h3>Les paiements</h3>
      <p>
        L'utilisateur autorise Bo à débiter sur la carte de crédit associée au compte de l'utilisateur pour tous les frais encourus par l'utilisateur en vertu des dispositions ci-dessus. Veuillez nous contacter dans les 3 jours si
        l'utilisateur n'est pas d'accord avec le/les frais chargés sur le compte de l'utilisateur.
      </p>
      <h3>La Responsabilité</h3>
      <p>
        Bo n'est pas responsable des problèmes liés à la qualité des boissons, de la nourriture ou du service fournis dans les emplacements de nos partenaires. L'utilisateur accepte que l'utilisateur indemnise et dégage Bo de toute
        responsabilité à l'égard de ces problèmes.
      </p>
      <h3>L’Application et  Le site Web</h3>
      <ul>
        <li>
          L'utilisateur est responsable de l'utilisation de l'application et du site Web et de son contenu. Bo ne sera pas tenu responsable de toute utilisation de l'application ou du site Web et de son contenu par l'un de ses utilisateurs
          qui ne serait pas conforme à la loi applicable. L'utilisateur sera tenu responsable s'il communique des informations ou des données inexactes, fausses ou concernant des tiers sans le consentement de ces derniers, ainsi que de
          toute utilisation abusive de ces données ou de ces informations.
        </li>
        <li>
          L'utilisateur reconnaît que le matériel sera téléchargé ou obtenu via Internet pour être utilisé avec l'application et le site Web, et l'utilisateur a choisi de l'utiliser à ses propres risques et l'utilisateur est responsable de
          tout dommage à son système informatique ou de la perte de données à la suite du téléchargement de contenu à partir du site Web.
        </li>
        <li>
          Bo n'accepte aucune responsabilité pour tout dommage résultant de l'inaccessibilité à l'Application ou aux services du site Web ou des dommages causés par des virus, des fichiers corrompus, des erreurs, des omissions, des
          interruptions de service, la suppression de contenu, des problèmes avec le réseau, avec les fournisseurs ou le téléphone et / ou connexions de transmission de données, accès non autorisé, altération de données, panne et / ou
          dysfonctionnement de l'équipement électronique de l'utilisateur.
        </li>
      </ul>
      <p>
        Nonobstant à toute disposition contraire dans les présentes, la responsabilité de Bo envers l'utilisateur en relation avec les produits, le service, l'application et le site Web sera à tout moment limitée à 100 % des frais payés par
        l'utilisateur en vertu des présentes conditions. En aucun cas, Bo ne sera tenu responsable des dommages ou pertes indirects, consécutifs, accessoires ou punitifs, qu'ils surviennent en vertu d'un contrat, d'un délit (y compris de la
        négligence), en droit ou en équité, et y compris, mais sans s'y limiter : la perte de profits réels ou anticipés, perte de revenus, perte d'utilisation, perte de clientèle, coût du capital ou toute perte indirecte, y compris les
        pertes au titre d'une interruption d'activité.
      </p>
      <h3>L’Indemnisation</h3>
      <p>L'utilisateur s'engage à indemniser, à payer et à décharger Bo de toute responsabilité contre toutes réclamations, responsabilités et/ou pertes de quelque nature que ce soit subi du fait ou en relation avec :</p>
      <ul>
        <li>tout dommage causé par l'utilisateur à un partenaire ou à son établissement ;</li>
        <li>les actes de négligence de l'utilisateur, ou les omissions, ou l'inconduite intentionnelle en relation avec le Service ou les présentes Conditions ;</li>
        <li>contre tout dommage, perte, décès ou blessure corporelle causé par l'utilisation par l'utilisateur des Produits ou du Service ; or</li>
        <li>de toute réclamation d'un tiers en rapport avec l'exécution par l'utilisateur des présentes Conditions ou l'utilisation des Produits ou du Service.</li>
      </ul>
      <h3>La Suspension</h3>
      <p>Bo peut suspendre, modifier, retirer ou restreindre la disponibilité de tout ou partie de nos Services, Application ou Site Web pour des raisons commerciales et opérationnelles.</p>
      <h3>Contact</h3>
      <p>En cas de problème lors de l'utilisation de nos Services, Application ou Site Web, veuillez contacter: support@bopaq.com</p>
      <h3>Les Interruptions</h3>
      <p>
        Bien que nous nous efforçons d'assurer un accès régulier à l'Application et au Site Web, la nature dynamique d'Internet et de son contenu peut ne pas nous permettre de fonctionner sans aucune suspension, interruption ou déconnexion
        liée à la mise à jour de l'Application et du Site Web. 
      </p>
      <h3>Les Détails du compte</h3>
      <p>
        L'utilisateur est responsable du maintien de la confidentialité et de la bonne utilisation des informations personnelles de l'utilisateur, y compris les informations d'identification pour accéder aux services restreints, et
        l’utilisateur est également responsable de tout dommage ou préjudice qui pourrait survenir contre Bo ou des tiers en tant que résultant d'une mauvaise utilisation par l'utilisateur, de la perte ou de la suppression de ces
        informations.
      </p>
      <h3>Les Données personnelles</h3>
      <p>
        L'utilisateur accepte et reconnaît que Bo peut collecter, utiliser et divulguer les données personnelles de l'utilisateur conformément à la politique de confidentialité de Bo qui s'applique aux utilisateurs qui accèdent à
        l'application et au site Web et qui utilisent les services sans effectuer d'achats. La politique de confidentialité aidera l'utilisateur à comprendre comment et à quelles fins Bo collecte et utilise les informations personnelles de
        l'utilisateur.
      </p>
      <h3>La Propriété intellectuelle</h3>
      <p>
        Le contenu de l'Application et du Site Web, y compris, mais sans s'y limiter, les œuvres, images, dialogues, musiques, sons, vidéos, documents, dessins, figures, logos et tout autre matériel publié sur l'Application et le Site Web,
        y compris navigations, pages Web, graphiques, outils, polices et conception de l'application et du site Web, diagrammes, méthodes, processus, fonctions, code HTML et logiciels qui font partie de l'application et du site Web, et sont
        protégés par le droit d'auteur et par toute autre propriété intellectuelle droits de Bo et des titulaires de droits concernés.
      </p>
      <h3>La Contrefaçon</h3>
      <p>
        La reproduction, totale ou partielle, et sous quelque forme que ce soit, des Produits, de l'Application et du Site et de leur contenu est interdit sans notre accord écrit préalable. Nous avons le droit exclusif d'autoriser ou
        d'interdire toute reproduction, qu'elle soit directe ou indirecte, temporaire ou permanente, ou de toute autre manière ou forme, et en totalité ou en partie, de l'Application et du Site et de tout leur contenu.
      </p>
      <h3>Le Site Internet</h3>
      <p>
        En ce qui concerne l'utilisation du Site Internet, l'utilisateur est seulement  autorisé à consulter le site Internet et son contenu. L'utilisateur est également autorisé à effectuer tous les autres actes de reproduction temporaires
        (autres qu'à des fins commerciales ou professionnelles), transitoires ou accessoires et faisant partie intégrante et indispensable de la consultation du Site et de son contenu.
      </p>
      <h3>L’Utilisation non autorisée</h3>
      <p>
        En aucun cas, l'utilisateur ne sera autorisé à faire un acte qui pourrait invalider ou autrement incompatible avec nos droits de propriété intellectuelle, y compris la copie, le transfert, la vente, l'ingénierie inverse ou la
        modification de notre application, de notre site Web ou de nos produits.
      </p>
      <h3>Les Liens vers des sites tiers</h3>
      <p>
        L'application et le site Web peuvent contenir des hyperliens (« liens » vers d'autres sites Web qui ne sont en aucun cas connectés à Bo. Bo ne contrôle ni effectue d'opérations de surveillance de ces sites et de leur contenu. Bo ne
        pas être tenu pour responsable du contenu de ces sites et des règles adoptées par eux, y compris en ce qui concerne la vie privée de l'utilisateur et le traitement des données personnelles de l'utilisateur lors de la navigation de
        l'utilisateur. Bo demande à l'utilisateur d'être vigilant lorsqu'il se connecte à ces sites Internet via les liens présents sur l'Application et le Site Internet et que l'utilisateur lise attentivement leurs conditions d'utilisation
        et règles de confidentialité. Bo rappelle à l'utilisateur que les présentes conditions et la politique de confidentialité ne s'appliquent pas aux sites Web exploités par des tiers. Bo fournit des liens vers d'autres sites Web
        exclusivement pour aider ses utilisateurs à rechercher et à naviguer et pour faciliter les liens vers d'autres sites Web sur Internet. L'activation de liens n'emporte aucune recommandation ou notification de Bo pour accéder et
        naviguer sur ces sites, ni aucune garantie quant à leur contenu, leurs services ou les biens fournis et vendus par eux aux internautes.
      </p>
      <h3>La Clause de non-responsabilité</h3>
      <p>
        Bien que Bo déploie des efforts raisonnables pour s'assurer que le contenu de l'application et du site Web est exact et ne contient aucune information incorrecte ou obsolète, Bo ne fait aucune déclaration, garantie ou de garantie ,
        expresse ou implicite, que le contenu du site Bo est exact, complet ou à jour. Bo ne sera pas tenu responsable de l'exactitude ni de l'exhaustivité du contenu publié sur l'Application ou le Site Web.
      </p>
      <h3>Le Contenu généré par l'utilisateur</h3>
      <p>
        Bo peut inclure des informations et des documents téléchargés par d'autres utilisateurs de l'application ou du site Web, y compris sur les tableaux d'affichage et les salles de discussion. Ces informations et ces documents n'ont pas
        été vérifiés ou approuvés par Bo.
      </p>
      <h3>La Violation des conditions</h3>
      <p>
        Bo se réserve le droit de cesser la participation de l'utilisateur au service pour quelque soit la raison, y compris en raison de la violation par l'utilisateur des présentes conditions. Les frais payés par l'utilisateur ne seront
        pas remboursés à l'utilisateur.
      </p>
      <h3>L’Annulation</h3>
      <p>
        L'utilisateur peut choisir d'annuler l'adhésion et la participation au Service à tout moment en contactant Bo. En outre, l'utilisateur accepte que tous les frais supplémentaires découlant des présentes Conditions puissent toujours
        être facturés à l'utilisateur. En cas de résiliation, l'utilisateur doit restituer tous les produits qu'il a empruntés et/ou payer tous les frais impayés accumulés.
      </p>
      <h3>La Loi applicable</h3>
      <p>
        L'utilisateur accepte que tout litige découlant de l'utilisation des Produits, du site Web ou de l'Application soit régi par les lois du Québec, Canada. Les tribunaux de Montréal auront la juridiction exclusive pour entendre toute
        question relative à la présente entente.
      </p>
      <h3>Le Langage</h3>
      <p>
        Les parties aux présentes conviennent à ce que l'entente soit rédigée en langue française. Les parties consentent à ce que le présent accord soit rédigé en français. The parties consent to have the present agreement drafted in
        French.
      </p>
      <h2>Annexe 1</h2>
      <p>
        Dans le cas du prêt gratuit, si l'utilisateur ne retourne pas le produit dans les 14 jours pour une raison quelconque ou que le produit est considéré comme endommagé suite à une inspection à la seule discrétion de Bo, Bo facturera
        immédiatement à l'utilisateur les frais indiqués selon le prix liste (plus TVQ ou TPS ) du format tel qu’indiqué ci dessous sur la carte de crédit de l'utilisateur.
      </p>
      <hr />
      <TermsContainersTable />
    </div>
  )
}

const Terms = ({ onClick, onReadAndAcceptTerms }: any) => {
  const { t } = useTranslation(['terms'])
  const lang = i18next.language

  useEffect(() => {
    window.onpopstate = () => {
      onClick()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onAccept = () => {
    onReadAndAcceptTerms()
    onClick()
  }

  return (
    <section className="terms-section justify-normal">
      <div className="bo-close">
        <Close onClick={onClick} />
      </div>
      {lang === 'fr' ? <TermsHtmlFr className="privacy-policy" /> : <TermsHtmlEng className="privacy-policy" />}
      <hr />
      <Link className="yellow-link" to="#" onClick={onAccept} replace>
        {t('i-read')}
      </Link>
      <hr />
    </section>
  )
}

export { Terms as default, TermsHtmlEng, TermsHtmlFr }
