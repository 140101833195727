import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useState } from 'react'
import { useHistory } from 'react-router'
import apiUtils from '../../api/apiUtils'
import StepCounter from '../../components/common/StepCounter'
import { MainModal } from '../../components/modal/Modals'
import i18n from '../../i18n'
import { CustomerPaths } from '../../routes/RoutePaths'
import CardSetupForm from './CardSetupForm'
import SuccessImage from '../../images/success.png'
import { useTranslation } from 'react-i18next'

const CustomerRegisterCC = () => {
  const { t } = useTranslation()
  const [showExplanation, setShowExplanation] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const selectedLanguage = i18n.language

  const history = useHistory()

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || '')

  const { setAuthToLocalStorage } = apiUtils()

  const onSubmit = (data: any) => {
    setAuthToLocalStorage(data.registerClientConfirmCC)
    setShowSuccess(true)
  }

  const onImportantClick = () => {
    setShowExplanation(true)
  }

  const onImportantClose = () => {
    setShowExplanation(false)
  }

  const onSuccess = (e: any) => {
    e.preventDefault()
    history.push(CustomerPaths.Home)
  }

  return (
    <main className="screen-container">
      {!!!showSuccess && (
        <>
          <div className="screen-header">
            <StepCounter className="solo-step-counter" text="4/4" percentage={360} />
            <h1>{t('payment-info')}</h1>
            <h2 className="linked-h2" onClick={onImportantClick}>
              {t('payment-important')}
            </h2>
          </div>
          <Elements stripe={stripePromise}>
            <CardSetupForm onSuccessCallback={onSubmit} />
          </Elements>
          {showExplanation && (
            <MainModal
              data={{
                position: 'center',
                cancelHook: onImportantClose,
                submitHook: onImportantClose,
                buttonText: t('i-understand'),
                show: showExplanation,
                title: t('why-payment-info'),
                content:
                  selectedLanguage === 'fr' ? (
                    <div className="privacy-policy">
                      <p>Bo est un service GRATUIT pour permettre aux consommateurs de choisir l'option réutilisable en emballage pour emporter. Les contenants Bo sont attribués à votre compte quand vous commandez avec votre code Bo.</p>
                      <hr />
                      <p>
                        MAIS Si vous tombé amoureux et decidez de garder vos contenants après la période de prêt de 14 jours, vous acceptez d'acheter les contenants aux prix listés dans l'Appendix 1 et nous prélèverons le paiement sur votre
                        carte de crédit automatiquement.
                      </p>
                      <hr />
                      <p>Merci de voir nos Termes et Conditions pour plus de renseignements et BOn appétit!</p>
                    </div>
                  ) : (
                    <div className="privacy-policy">
                      <p>Bo is a FREE service for customers to create an account & opt for reusable takeout packaging at participating restaurants by providing your Bo code.</p>
                      <hr />
                      <p>
                        HOWEVER If you decide to keep a Bo container past the 14-day borrow period (because they stack your packed lunch ridiculously well in your fridge), you agree to purchase the unit at list price plus applicable taxes
                        (see Appendix 1 in our Terms & Conditions) & payment will be automatically charged to your credit card.
                      </p>
                    </div>
                  ),
              }}
            />
          )}
        </>
      )}
      {showSuccess && (
        <>
          <div className="screen-header">
            <img src={SuccessImage} alt="Green tick" />
            <h1>{t('account-created')}</h1>
            <h2>{t('account-created-thank-you')}</h2>
          </div>
          <div className="screen-footer-section">
            <button className="button-medium main-button" onClick={onSuccess}>
              {t('go-home')}
            </button>
          </div>
        </>
      )}
    </main>
  )
}

export { CustomerRegisterCC as default }
