import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PrivacyPolicyEng from '../../containers/common/PrivacyPolicies/PrivacyPolicyEng'
import PrivacyPolicyFr from '../../containers/common/PrivacyPolicies/PrivacyPolicyFr'
import { MainModal } from '../modal/Modals'
import { TermsHtmlEng, TermsHtmlFr } from '../TermsAndConditions'
import './TosPPLabels.css'

const ToSPPLabel = () => {
  const [showToS, setShowToS] = useState(false)
  const [showPP, setShowPP] = useState(false)

  const { i18n, t } = useTranslation()
  const selectedLanguage = i18n.language

  const onToSClick = () => {
    setShowToS(true)
  }

  const onToSClose = () => {
    setShowToS(false)
  }

  const onPPClick = () => {
    setShowPP(true)
  }

  const onPPClose = () => {
    setShowPP(false)
  }
  return (
    <>
      {showToS && (
        <MainModal
          data={{
            position: 'center',
            cancelHook: onToSClose,
            submitHook: onToSClose,
            buttonText: t('accept-and-continue'),
            show: showToS,
            title: t('terms-of-service'),
            content: selectedLanguage === 'fr' ? <TermsHtmlFr className="modalTerms" showHeading={false} /> : <TermsHtmlEng className="modalTerms" showHeading={false} />,
          }}
        />
      )}
      {showPP && (
        <MainModal
          data={{
            position: 'center',
            cancelHook: onPPClose,
            submitHook: onPPClose,
            buttonText: t('accept-and-continue'),
            show: showPP,
            title: t('privacy-policy'),
            content: selectedLanguage === 'fr' ? <PrivacyPolicyFr className="modalTerms" showHeading={false} /> : <PrivacyPolicyEng className="modalTerms" showHeading={false} />,
          }}
        />
      )}
      <label className="tos-pp">
        <span onClick={onToSClick}>{t('terms-of-service')}</span> {t('and')} <span onClick={onPPClick}>{t('privacy-policy')}</span>
      </label>
    </>
  )
}

export { ToSPPLabel }
