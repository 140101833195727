import i18next from 'i18next'
import BackButton from '../../components/BackButton'
import Logo from '../../components/Logo'

const CCFor = () => {
  const lang = i18next.language

  return (
    <main className="justify-normal">
      <BackButton />
      <Logo></Logo>
      {lang === 'fr' ? (
        <div className="privacy-policy">
          <p>Bo est un service GRATUIT pour permettre aux consommateurs de choisir l'option réutilisable en emballage pour emporter. Les contenants Bo sont attribués à votre compte quand vous commandez avec votre code Bo.</p>
          <hr />
          <p>
            MAIS Si vous tombé amoureux et decidez de garder vos contenants après la période de prêt de 14 jours, vous acceptez d'acheter les contenants aux prix listés dans l'Appendix 1 et nous prélèverons le paiement sur votre carte de
            crédit automatiquement.
          </p>
          <hr />
          <p>Merci de voir nos Termes et Conditions pour plus de renseignements et BOn appétit!</p>
        </div>
      ) : (
        <div className="privacy-policy">
          <p>Bo is a FREE service for customers to create an account & opt for reusable takeout packaging at participating restaurants by providing your Bo code.</p>
          <hr />
          <p>
            HOWEVER If you decide to keep a Bo container past the 14-day borrow period (because they stack your packed lunch ridiculously well in your fridge), you agree to purchase the unit at list price plus applicable taxes (see Appendix
            1 in our Terms & Conditions) & payment will be automatically charged to your credit card.
          </p>
        </div>
      )}
    </main>
  )
}

export { CCFor as default }
