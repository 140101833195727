import './LanguageSelector.css'
import { useTranslation } from 'react-i18next'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { ReactComponent as GlobeIcon } from '../icons/globe.svg'

const LanguageRow = ({ className }: { className?: string }) => {
  const { i18n } = useTranslation()
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language)

  useEffect(() => {
    if (selectedLanguage !== 'fr') setSelectedLanguage('en')
  }, [selectedLanguage])

  const onLanguageToggle = () => {
    const currentLang = i18n.language
    switch (currentLang) {
      case 'fr':
        setSelectedLanguage('en')
        i18n.changeLanguage('en')
        break
      case 'en-GB':
      case 'en':
      default:
        setSelectedLanguage('fr')
        i18n.changeLanguage('fr')
        break
    }
  }

  return (
    <section className={`language-selector-row ${className && className}`} onClick={e => onLanguageToggle()}>
      <label className={`cancel ${selectedLanguage === 'fr' && 'active-lang'}`}>FR</label>
      <label>/</label>
      <label className={`cancel ${selectedLanguage === 'en' && 'active-lang'}`}>EN</label>
    </section>
  )
}

type LanguageSelectData = {
  className?: string
  setValue?: Dispatch<SetStateAction<string>>
}

const LanguageSelect = (data: LanguageSelectData) => {
  const { i18n } = useTranslation()
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language)

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage)
    if (data.setValue) data.setValue(selectedLanguage)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage])

  return (
    <>
      <div className={`languageSelectWrapper ${data.className ? data.className : ''}`}>
        <GlobeIcon />
        <select value={selectedLanguage} className="languageSelect" onChange={e => setSelectedLanguage(e.target.value)}>
          <option value="fr">Français</option>
          <option value="en">English</option>
        </select>
      </div>
    </>
  )
}

export { LanguageRow, LanguageSelect }
