import i18next from 'i18next'
import BackButton from '../../components/BackButton'
import Logo from '../../components/Logo'
import PrivacyPolicyEng from './PrivacyPolicies/PrivacyPolicyEng'
import PrivacyPolicyFr from './PrivacyPolicies/PrivacyPolicyFr'

const PrivacyPolicyPage = () => {
  const lang = i18next.language

  return (
    <main className="justify-normal">
      <BackButton />
      <Logo></Logo>
      {lang === 'fr' ? (
        // <div className="privacy-policy">
        //   <h2>PROTECTION DES DONNÉES À CARACTÈRE PERSONNEL</h2>
        //   <p>Version française à venir! Merci de patienter.</p>
        // </div>
        <PrivacyPolicyFr />
      ) : (
        <PrivacyPolicyEng />
      )}
    </main>
  )
}

export { PrivacyPolicyPage as default }
